<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ UserData }}
          Update Akses
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>



      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <b-form-group label="Menu" label-for="menu">
            <!-- {{ menuOptions }} -->
            <Treeselect :multiple="true" :options="menuOptions" placeholder="Pilih Menu" v-model="userData.akses" />
            <Treeselect-value :value="value" />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Save
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="$emit('update:is-add-new-user-sidebar-active', false), $emit('refetch-data')">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs, ref, onMounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import vSelect from 'vue-select'
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import useAppConfig from '@core/app-config/useAppConfig'
import axios from '@axios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    Treeselect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      value: null,
      // define options
      options: [{
        id: 'fruits',
        label: 'Fruits',
        color: 'red',
        children: [{
          id: 'apple',
          label: 'Apple 🍎',
          isNew: true,
        }, {
          id: 'grapes',
          label: 'Grapes 🍇',
        }, {
          id: 'pear',
          label: 'Pear 🍐',
        }, {
          id: 'strawberry',
          label: 'Strawberry 🍓',
        }, {
          id: 'watermelon',
          label: 'Watermelon 🍉',
        }],
      }, {
        id: 'vegetables',
        label: 'Vegetables',
        children: [{
          id: 'corn',
          label: 'Corn 🌽',
        }, {
          id: 'carrot',
          label: 'Carrot 🥕',
        }, {
          id: 'eggplant',
          label: 'Eggplant 🍆',
        }, {
          id: 'tomato',
          label: 'Tomato 🍅',
        }],
      }],
    }
  },
  setup(props, { emit }) {
    const menuOptions = ref([])
    onMounted(async () => {
      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/hak-akses-menu/view`)
      const DataAPI = result.data.data
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = DataAPI.length; i < len; i++) {
        combo.push({ id: `${DataAPI[i].id}`, label: `${DataAPI[i].title}`, children: DataAPI[i].children })
      }
      menuOptions.value = DataAPI
    })

    const toast = useToast()

    const { userData } = toRefs(props)

    const onSubmit = () => {
      store.dispatch('app-user/crudData', userData.value)
        .then(response => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          if (response.data.message === 'Data Saved' || response.data.message === 'Data Updated') {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: response.data.message,
                variant: 'success',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: 'Error Saving Data / Duplicate Data',
                variant: 'danger',
              },
            })
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      userData,
      onSubmit,
      menuOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>

<style>

  /* Menimpa gaya bawaan */
  .vue-treeselect__option .vue-treeselect__label {
    color: rgb(17, 17, 17) !important;
  }

  .vue-treeselect__option--selected .vue-treeselect__label {
    color: blue !important;
  }

  .vue-treeselect__option--highlight .vue-treeselect__label {
    color: blue !important;
  }
</style>